import * as Sentry from "@sentry/vue";

export default function initSentry(app, router) {
  const envID = import.meta.env.VITE_APP_ENV;

  if (envID !== "production") {
    return;
  }

  const serverURL = import.meta.env.VITE_APP_API_BASE_URL;
  const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
  const sentryAuthToken = import.meta.env.VITE_SENTRY_AUTH_TOKEN;

  Sentry.init({
    app,
    dsn: sentryDSN,
    authToken: sentryAuthToken,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [serverURL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
